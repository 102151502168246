


























































import Vue from 'vue';
import Component from 'vue-class-component';
import StepAudience from '@/components/survey-wizard/StepAudience.vue';
import StepChooseTemplate from '@/components/survey-wizard/StepChooseTemplate.vue';
import StepDataUsage from '@/components/survey-wizard/StepDataUsage.vue';
import StepUseTemplate from '@/components/survey-wizard/StepUseTemplate.vue';
import {AlertMessage, SurveyResource} from '@/interfaces';
import {surveyApi} from '@/api';
import {ResourceType, SurveyType} from '@/enums';
import {sleep} from '@/utility';
import Page from '@/components/ui/Page.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import StepChooseExisting from '@/components/survey-wizard/StepChooseExisting.vue';
import StepName from '@/components/survey-wizard/StepName.vue';
import {orderBy} from 'lodash';

@Component({
  components: {
    StepName,
    StepChooseExisting,
    PageTitle,
    StepAudience,
    StepChooseTemplate,
    StepDataUsage,
    StepUseTemplate,
    Page,
  },
})
export default class SurveyWizard extends Vue {
  private alert: AlertMessage | null = null;
  private saving = false;
  private showStepAudience = false;
  private showStepChooseTemplate = false;
  private showStepDataUsage = false;
  private showStepUseTemplate = true;
  private showStepChooseExisting = false;
  private showStepName = false;

  private audience: 'targeted' | 'open' | null = null;
  private dataUsage: 'integrated' | 'non-integrated' | null = null;
  private useTemplate: 'template' | 'scratch' | 'existing' | null = null;
  private template: string | null = null;
  private surveyName = '';

  private surveys: SurveyResource[] = [];
  private templates: SurveyResource[] = [];
  private loading = false;

  private progress = 25;

  public async created(): Promise<void> {
    this.loading = true;
    const surveys = await surveyApi.list({filters: {template: '0'}});
    const templates = await surveyApi.list({filters: {template: '1'}});
    this.loading = false;

    this.surveys = orderBy(surveys, ['attributes.name'], ['asc']);
    this.templates = orderBy(templates, ['attributes.name'], ['asc']);
  }

  stepUseTemplateHandler() {
    switch (this.useTemplate) {
      case 'template':
        this.showStepUseTemplate = false;
        this.showStepChooseTemplate = true;
        this.progress = 33;
        break;
      case 'existing':
        this.showStepUseTemplate = false;
        this.showStepChooseExisting = true;
        this.progress = 33;
        break;
      case 'scratch':
        this.showStepUseTemplate = false;
        this.showStepDataUsage = true;
        this.progress = 33;
        break;
      default:
        throw Error(`Invalid template option: ${this.useTemplate}`);
    }
  }

  stepChooseTemplateHandler(value: string) {
    if (value === 'back') {
      this.showStepChooseTemplate = false;
      this.showStepUseTemplate = true;
      this.progress = 33;
      return;
    }
    this.showStepChooseTemplate = false;
    this.showStepName = true;
    this.progress = 66;
    console.log(this.useTemplate);
  }

  stepChooseExistingHandler(value: string) {
    if (value === 'back') {
      this.showStepChooseExisting = false;
      this.showStepUseTemplate = true;
      this.progress = 33;
      return;
    }
    this.showStepChooseExisting = false;
    this.showStepName = true;
    this.progress = 66;
  }

  stepDataUsageHandler(value: string): void {
    if (value === 'back') {
      this.showStepUseTemplate = true;
      this.showStepDataUsage = false;
      this.progress = 25;
      return;
    }

    this.showStepDataUsage = false;
    this.showStepAudience = true;
    this.progress = 50;
  }

  stepAudienceHandler(value: string) {
    if (value === 'back') {
      this.showStepAudience = false;
      this.showStepDataUsage = true;
      this.progress = 50;
      return;
    }

    this.showStepAudience = false;
    this.showStepName = true;
    this.progress = 75;
  }

  stepNameHandler(value: string) {
    if (value === 'back') {
      this.showStepName = false;

      switch (this.useTemplate) {
        case 'template':
          this.showStepChooseTemplate = true;
          this.progress = 33;
          break;
        case 'existing':
          this.showStepChooseExisting = true;
          this.progress = 33;
          break;
        case 'scratch':
          this.showStepAudience = true;
          this.progress = 50;
          break;
        default:
          throw Error(`Invalid template option: ${this.useTemplate}`);
      }
      return;
    }

    this.createSurvey();
  }

  public async createSurvey() {
    let template = null;
    if (this.useTemplate === 'template') {
      template = this.templates.filter(
        survey => survey.id === String(this.template)
      )[0];
    } else if (this.useTemplate === 'existing') {
      template = this.surveys.filter(
        survey => survey.id === String(this.template)
      )[0];
    }

    const useTemplate =
      this.useTemplate === 'template' || this.useTemplate === 'existing';
    const templateType =
      this.template !== null ? template?.attributes.type : null;
    const type = useTemplate
      ? (templateType as SurveyType)
      : (`${this.dataUsage}-${this.audience}` as SurveyType);

    if (useTemplate) {
      console.log(`create ${this.surveyName} from template ${type}`);
    } else {
      console.log(`create ${this.surveyName} from scratch ${type}`);
    }

    this.saving = true;
    try {
      if (process.env.NODE_ENV === 'development') {
        await sleep(2000);
      }
      const templateId =
        Number(this.template) === 0 ? undefined : Number(this.template);
      const survey = await surveyApi.post({
        type: ResourceType.Survey,
        attributes: {
          name: this.surveyName,
          template: templateId,
          type: type,
        },
      });

      await this.$router.push({name: 'surveys-edit', params: {id: survey.id}});
    } catch (err) {
      console.error(err.response.data);
      this.alert = {
        type: 'error',
        message: `There was an error creating this survey.`,
      };
    }
    this.saving = false;
  }
}
