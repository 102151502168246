

























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyResource} from '@/interfaces';

@Component({})
export default class StepName extends Vue {
  @Prop() private value!: string;
  @Prop() private saving!: boolean;
  @Prop() private useTemplate!: 'existing' | 'template' | 'scratch';
  @Prop() private template!: string | null;
  @Prop({default: []}) private templates!: SurveyResource[];
  @Prop({default: []}) private surveys!: SurveyResource[];

  public async created(): Promise<void> {
    // if we are using a template or existing survey, get the survey name and
    // use it to start the user
    if (this.useTemplate === 'scratch') {
      this.$emit('update:value', '');
      return;
    } else if (this.useTemplate === 'template') {
      const template = this.templates.filter(
        survey => survey.id === String(this.template)
      );
      if (template.length === 0) {
        return;
      }
      this.$emit('update:value', template[0].attributes.name + ' Copy');
    } else if (this.useTemplate === 'existing') {
      const template = this.surveys.filter(
        survey => survey.id === String(this.template)
      );
      if (template.length === 0) {
        return;
      }
      this.$emit('update:value', template[0].attributes.name + ' Copy');
    }
  }
}
