
















































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: ['value', 'saving'],
})
export default class StepAudience extends Vue {}
