




























import Vue from 'vue';
import Component from 'vue-class-component';
import {SurveyResource} from '@/interfaces';
import {Prop} from 'vue-property-decorator';

@Component({})
export default class StepChooseTemplate extends Vue {
  @Prop() private value!: string;
  @Prop({default: []}) private templates!: SurveyResource[];
}
